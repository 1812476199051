import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { CreateTheme } from './utils';
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "theme-creation-tool"
    }}>{`Theme creation tool`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <p>{`This tool is a friendly way for generating the theme file helping you to add the information you need and automatically generating
the theme file, however once you get it you still need to put it in the app and type it correctly. The `}<a parentName="p" {...{
        "href": "./create-theme"
      }}>{`Create theme`}</a>{` doc has good information about the structure of a theme.`}</p>
    <p>{`While you do changes the tool will start reacting to them and changing the colors, fonts, sizes, etc... So you can start getting a pick to the theme you are selecting, once you are done we suggest you to test it in our `}<a parentName="p" {...{
        "href": "./test-custom-theme"
      }}>{`test custom theme`}</a>{` page.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We recommend you open in 1 tab this page and in a separate tab the testing page so you can test the generated theme`}</p>
    </blockquote>
    <CreateTheme mdxType="CreateTheme" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      